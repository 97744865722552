import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAtom } from 'jotai';
import { authAtom, notificationsAtom } from '../../common/store';

import ApiService from '../../services/ApiService';
import NotificationsService from '../../services/NotificationsService';
import { Dropdown } from '../../common/components/Dropdown';
import { Notifications } from '../../common/components/Notifications';
import { Header } from '../../common/components/Header';
import { Nav } from '../../common/components/Nav';

export default function EditUserPage() 
{
    let { id } = useParams()
    const [user, setUser] = React.useState()

    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const [auth, setAuth] = useAtom(authAtom)
    const navigate = useNavigate()
    
    const [showLoader, setShowLoader] = React.useState(false)
    const [physicians, setPhysicians] = React.useState()

    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [physician, setPhysician] = React.useState('')

    React.useEffect(() => {
        async function getUser() {
            let response = await ApiService.getUser(id)
            if ('status' in response && response.status === 'require_auth') {
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
                navigate('/login')
            } else if ('status' in response && response.status === 'error') {
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'edituser'))
            } else {
                setUser(response.data)
                setFirstName(response.data.first_name)
                setLastName(response.data.last_name)
                setPhysician(response.data.physician_id.toString())
            }
        }
        getUser()

        async function getPhysicians() {
            let response = await ApiService.getPhysicians()
            if ('status' in response && response.status === 'require_auth') {
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
                navigate('/login')
            } else if ('status' in response && response.status === 'error') {
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'records'))
            } else {
                let data = new Map([['', '- Selecteaza -']])
                console.log(response.data)
                Object.entries(response.data).forEach(([key, value]) => {
                    data.set(key, value)
                })
                console.log(data)
                setPhysicians(data)
            }
        }
        getPhysicians()
    }, [])

    const handleSubmit = async () => {
        setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'profile'))
        setShowLoader(true)
        let response = await ApiService.handleUpdateUser(user.id, firstName, lastName, physician)
        if (response.status === 'success') {
            setNotifications(NotificationsService.addNotification(notifications, response.message, 'notice', 'records'))
        } else if (response.status === 'require_auth') {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
            navigate('/login')
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'records'))
        }
        setShowLoader(false) 
    }

    return (
        <div className="page user-edit-page container-center app-page">
            <div>
                <Header title="Editare Utilizator" view="users" />
                <div className="page-body">
                    <div className="back-arrow" onClick={() => {navigate('/users')}}>
                        <svg width="29" height="18" viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_314_403)">
                                <path fill="var(--white)" d="M11.579 1.39585C11.5501 0.841836 11.3581 0.448077 11.0019 0.219532C10.1092 -0.359816 9.23013 0.327471 8.5867 0.88369L0.537495 7.91958C-0.180142 8.55069 -0.180142 9.4489 0.537495 10.0795L8.40375 16.9562C9.07777 17.5444 10.0339 18.409 11.0019 17.7812C11.3581 17.5515 11.5501 17.1572 11.579 16.6037V12.4018H28.6409C28.838 12.4018 28.9994 12.2443 28.9994 12.0527V5.94638C28.9994 5.75528 28.8374 5.59778 28.6409 5.59778H11.579V1.39585V1.39585Z"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_314_403">
                                    <rect width="29" height="18" fill="var(--white)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>

                    <Notifications />
                    <div className="user-list">
                        {
                            (typeof(user) !== 'object') ?
                            <div>Se incarca ...</div>
                        :
                            <div className="user-edit">
                                <div className="field-container">
                                    <label htmlFor="first-name">Nume</label>
                                    <input 
                                        id="first-name" 
                                        type="text" 
                                        value={firstName} 
                                        onChange={(event) => {setFirstName(event.target.value)}}
                                    />
                                </div>
                                <div className="field-container">
                                    <label htmlFor="last-name">Prenume</label>
                                    <input 
                                        id="last-name" 
                                        type="text" 
                                        value={lastName} 
                                        onChange={(event) => {setLastName(event.target.value)}}
                                    />
                                </div>
                                <div className="row-container">
                                    <div className="field-container">
                                        <label htmlFor="physician">Medic</label>
                                        {typeof physicians === 'undefined' ? (
                                            <div className="input">
                                                <div className="btn-loader white-bg"></div>
                                            </div>
                                        ) : (
                                            <Dropdown
                                                trigger={<button><span className="selected">{physicians.get(physician)}</span></button>}
                                                menu={Array.from(physicians.keys()).map((physicianId, idx) => { 
                                                    return (
                                                        <button onClick={() => setPhysician(physicianId)}>{physicians.get(physicianId)}</button>
                                                    )
                                                })}
                                            />
                                        )}
                                    </div>
                                </div>
                                {showLoader ? (
                                    <button className="btn disabled">
                                        <div className="btn-loader"></div>
                                    </button>
                                ) : (
                                    <button 
                                        className="btn" 
                                        onClick={() => handleSubmit()}
                                    >
                                        Salveaza
                                    </button>
                                )}
                            </div>
                    }

                    </div>
                </div>
                <Nav active={'users'} />
            </div>
        </div>
    )
}