import React from "react";
import {useNavigate, useSearchParams} from 'react-router-dom';
import { Buffer } from 'buffer';

import ApiService from "../../services/ApiService";
import NotificationsService from "../../services/NotificationsService";

import { Notifications } from "../../common/components/Notifications";
import { HeaderPublic } from "../../common/components/HeaderPublic";

import { useAtom } from "jotai";
import { notificationsAtom } from "../../common/store";

export default function VerifyResetCodePage() 
{
    const [searchParams] = useSearchParams()

    console.log(searchParams.get('hash'))
    let queryEmail = 'andreiydaca@yahoo.com'
    try {
        queryEmail = Buffer.from(searchParams.get('hash'), 'base64').toString('ascii')
    } catch (error) {
    }
    console.log(queryEmail)

    const [email, setEmail] = React.useState(queryEmail)
    const [code, setCode] = React.useState(typeof searchParams.get('code') == 'string' ? searchParams.get('code') : '')

    const [showLoader, setShowLoader] = React.useState(false)

    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const navigate = useNavigate()

    const handleSubmit = async () => {
        setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'verify-reset-code'))
        setShowLoader(true)
        let response = await ApiService.handleVerifyCode(email, code)
        if (response.status === 'success') {
            setNotifications(NotificationsService.addNotification(notifications, response.message, 'notice', 'reset-password'))
            navigate('/reset-password', {
                state: {
                    email: email,
                    code: code
                }
            })
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'verify-reset-code'))
            setShowLoader(false) 
        }
    }

    return (
        <div className="page login-page container-center form-page public-header-page">
            <div>
                <HeaderPublic title={'Resetare Parola'} />
                <Notifications />
                <div className="form login-form">
                    <div className="field-container">
                        <label htmlFor="email">E-mail</label>
                        <input 
                            id="email" 
                            type="email" 
                            placeholder="email@email.com" 
                            value={email} 
                            onChange={(event) => {setEmail(event.target.value)}}
                        />
                    </div>
                    <div className="field-container">
                        <label htmlFor="code">Cod Resetare</label>
                        <input 
                            id="code" 
                            type="text" 
                            value={code} 
                            onChange={(event) => {setCode(event.target.value)}}
                        />
                    </div>
                    <div className="link">
                        <a onClick={(ev) => {
                                ev.preventDefault(); 
                                setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'verify-reset-code'))
                                navigate('/login')}
                            } href="/login"
                        >Logare</a>
                    </div>

                    {showLoader ? (
                        <button className="btn disabled">
                            <div className="btn-loader"></div>
                        </button>
                    ) : (
                        <button 
                            className="btn" 
                            onClick={() => handleSubmit()}
                        >
                            Valideaza Cod Resetare
                        </button>
                    )}

                    <div className="after-submit">
                        Nu aveti codul de resetare? <a onClick={(ev) => {
                                ev.preventDefault(); 
                                setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'request-reset'))
                                navigate('/request-reset-password')}
                            } href="/request-reset-password"
                        >Genereaza Cod Resetare</a>
                    </div>
                </div>
            </div>
        </div>
    )
}