import React from 'react'
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom'
import AuthService from '../../services/ApiService'
import NotificationsService from '../../services/NotificationsService'

import { useAtom } from "jotai"
import { authAtom, notificationsAtom, tokenAtom, unreadAtom } from '../store'
import { decrypt } from "../helpers/crypt"

export const RouteGuard = () => {
    
    const navigate = useNavigate()
    const location = useLocation()
    
    const [notifications, setNotifications] = useAtom(notificationsAtom)
    const [auth, setAuth] = useAtom(authAtom)
    console.log([auth, location.pathname ])

    let isTokenSet = false;

    const [token] = useAtom(tokenAtom)
    const [unread, setUnread] = useAtom(unreadAtom)

    //Check if JWT token is set
    token ? isTokenSet=true : isTokenSet=false

    console.log('Has token: ' + isTokenSet)
    console.log(auth)

    if (!isTokenSet) {
        // No token in storage -> login
        return <Navigate to="/login" />
    }
    
    if (auth != null && auth.user.email_verified_at == null && location.pathname !== '/verify-resend') {
        // We have auth in state, but use is not verified -> verify-resend
        setNotifications(NotificationsService.addNotification(notifications, 'Va rugam sa confirmati adresa de email', 'error', 'verify-resend-code'))
        navigate('/verify-resend')
    } else if (auth == null) {
        // Auth object not set in state, get it from API
        AuthService.setAuthToken(decrypt(token));
        console.log('get logged it user')
        AuthService.getLoggedInUser().then((response) => {
            if ('data' in response && 'role' in response.data) {
                console.log('start set Auth')
                setAuth({
                    user: response.data,
                    role: response.data.role.name,
                })

                setUnread(response.data.unread)

                if (response.data.email_verified_at == null) {
                    // Valid token but not verified email -> verify-resend
                    setNotifications(NotificationsService.addNotification(notifications, 'Va rugam sa confirmati adresa de email', 'error', 'verify-resend-code'))
                    navigate('/verify-resend')
                }
            } else {
                console.log('Token expired -> login')
                // Token expired -> login
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
                navigate('/login')
                setAuth(null)
            }
        })
        return <></>
    } 

    // All good, return initial route
    return <Outlet />
};