import React from 'react';

import logoImage from '../../assets/images/logo.png'
 
export const HeaderPublic = ({ title }) => {

    React.useEffect(() => {
        document.title = "MediPrax - " + title
    }, [title]);

    return (
        <header>
            <img  src={logoImage} alt="Mediprax"/>
            <h1>{title}</h1>
        </header>
    ) 
};
 