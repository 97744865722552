import React from "react";
import {useNavigate} from 'react-router-dom';

import ApiService from "../../services/ApiService";
import NotificationsService from "../../services/NotificationsService";

import { Notifications } from "../../common/components/Notifications";
import { HeaderPublic } from "../../common/components/HeaderPublic";

import { useAtom } from "jotai";
import { notificationsAtom } from "../../common/store";

export default function RequestResetPage() 
{
    const [email, setEmail] = React.useState('andreiydaca@yahoo.com')

    const [showLoader, setShowLoader] = React.useState(false)

    const [notifications, setNotifications] = useAtom(notificationsAtom);

    const navigate = useNavigate()

    const handleSubmit = async () => {
        setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'request-reset'))
        setShowLoader(true)
        let response = await ApiService.handleRequestReset(email)
        if (response.status === 'success') {
            setNotifications(NotificationsService.addNotification(notifications, response.message, 'notice', 'verify-reset-code'))
            navigate('/verify-reset-code')
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'request-reset'))
            setShowLoader(false) 
        }
    }

    return (
        <div className="page login-page container-center form-page public-header-page">
            <div>
                <HeaderPublic title={'Generare cod de resetare'} />
                <Notifications />
                <div className="form login-form">
                    <div className="field-container">
                        <label htmlFor="email">E-mail</label>
                        <input 
                            id="email" 
                            type="email" 
                            placeholder="email@email.com" 
                            value={email} 
                            onChange={(event) => {setEmail(event.target.value)}}
                        />
                    </div>
                    <div className="link">
                        <a onClick={(ev) => {
                                ev.preventDefault(); 
                                setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'request-reset'))
                                navigate('/login')}
                            } href="/login"
                        >Logare</a>
                    </div>


                    {showLoader ? (
                        <button className="btn disabled">
                            <div className="btn-loader"></div>
                        </button>
                    ) : (
                        <button 
                            className="btn" 
                            onClick={() => handleSubmit()}
                        >
                            Genereaza Cod
                        </button>
                    )}

                    <div className="after-submit">
                        Aveti un cod de resetare? <a onClick={(ev) => {
                                ev.preventDefault(); 
                                setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'request-reset'))
                                navigate('/verify-reset-code')}
                            } href="/verify-reset-code"
                        >Resetare Parola</a>
                    </div>
                </div>
            </div>
        </div>
    )
}