import React from 'react';
import { useNavigate } from 'react-router-dom';

import ApiService from '../../services/ApiService';
import NotificationsService from '../../services/NotificationsService';

import { useAtom } from 'jotai';
import { authAtom, composeMessagesAtom, notificationsAtom } from '../../common/store';

export default function NewMessage({ conversation, setConversation }) 
{
    const [newMessages, setNewMessages] = useAtom(composeMessagesAtom);
    const [showLoader, setShowLoader] = React.useState(false)
    
    
    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const navigate = useNavigate()
    
    const [auth] = useAtom(authAtom);
    const messageKey = auth.user.id + '-' + conversation.id

    const handleSendMessage = async () => {
        if (showLoader) {
            return
        }

        setShowLoader(true)
        setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'chat'))
        let response = await ApiService.handleSendMessage(newMessages[messageKey], conversation.id)
        if (response.status === 'success') {
            setNewMessages(prevState => ({ ...prevState, [messageKey]: '' }))
            setConversation(response.data)
        } else if (response.status === 'require_auth') {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
            navigate('/login')
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'chat'))
        }
        setShowLoader(false) 
    }

    return (
        <div className={showLoader ? 'new-message sending' : 'new-message'}>
            <div>
                <div>
                    <input 
                        type="text"
                        autoFocus
                        autoComplete="off"
                        placeholder="Scrieti aici ..."
                        value={messageKey in newMessages ? newMessages[messageKey] : ''}
                        onChange={(event) => {setNewMessages(prevState => ({ ...prevState, [messageKey]: event.target.value }))}}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                handleSendMessage()
                            }
                        }}
                    />

                    <svg onClick={() => {handleSendMessage()}} width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="var(--primary-color)" d="M0.00999999 17.7251L21 8.86255L0.00999999 0L0 6.89309L15 8.86255L0 10.832L0.00999999 17.7251Z"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}