import React from "react";
import { useNavigate } from "react-router-dom";

import { useAtom } from "jotai";
import { authAtom } from "../../common/store";

import logoImage from '../../assets/images/logo.png'

export default function LoaderPage() 
{
    console.log('Render Loader Page')

    const [auth] = useAtom(authAtom)
    const navigate = useNavigate()

    
    React.useEffect(() => {
        document.title = "MediPrax Application"
        
        if (auth.role === 'Pacient') {
            navigate('/records')
        } else if (auth.role === 'Receptie') {
            navigate('/users')
        } else {
            navigate('/conversations')
        }
    }, []);

    return (
        <div className="page loader-page container-center">
            <img  src={logoImage} alt="Bine ati venit!"/>
        </div>
    )
}