import React from 'react';
import NotificationsService from '../../services/NotificationsService';

import { useAtom } from 'jotai';
import { notificationsAtom } from '../store';
 
export const Notifications = () => {

    const [notifications, setNotifications] = useAtom(notificationsAtom);
    
    return notifications.length === 0 ? 
    <></> 
    : 
    <div id='notifications'>
        {Object.keys(notifications).map((id, i) => {  
            return (
                notifications[id].visible ?
                    <div 
                        key={i} 
                        className={'notification ' + notifications[id].type}
                        onClick={() => setNotifications(NotificationsService.dismiss(id, notifications))}
                    >
                        {notifications[id].message}
                    </div>
                :
                    <div key={i} ></div>
            ) 
        })}
    </div>;
};
 