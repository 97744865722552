class NotificationModel {

  _id
  _field
  _message = ""
  _type = "notice"
  _group = ""
  _visible = true
  _created_at

  constructor() {
    this.id = (Math.random() + 1).toString(36).substring(2)
  }

  get field() {
    return this._field
  }
  set field(value) {
    this._field = value
  }
  
  get message() {
    return this._message
  }
  set message(value) {
    this._message = value
  }

  get type() {
    return this._type
  }
  set type(value) {
    this._type = value
  }

  get group() {
    return this._group
  }
  set group(value) {
    this._group = value
  }

  get visible() {
    return this._visible
  }
  set visible(value) {
    this._visible = value
  }

  get created_at() {
    return this._created_at
  }
  set created_at(value) {
    this._created_at = value
  }
}

export default NotificationModel