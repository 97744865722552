import React from 'react';

import { Header } from '../../common/components/Header';
import { Nav } from '../../common/components/Nav';
import Records from './Records';
import AddRecord from './AddRecord';
import { useNavigate, useParams } from 'react-router-dom';
import ApiService from '../../services/ApiService';

import NotificationsService from '../../services/NotificationsService';
import { Notifications } from '../../common/components/Notifications';

import { useAtom } from 'jotai';
import { authAtom, notificationsAtom, recordsAtom } from '../../common/store';

export default function RecordsPage() 
{
    console.log('Render Records Page')

    let { userId } = useParams()
    const [auth] = useAtom(authAtom)
    
    const [records, setRecords] = useAtom(recordsAtom);
    const [notifications, setNotifications] = useAtom(notificationsAtom);
    
    console.log(records)
    const navigate = useNavigate()

    React.useEffect(() => {
        async function getRecords() {
            let response = await ApiService.getRecords((typeof(userId) !== 'undefined') ? userId : auth.user.id)
            console.log(response)
            if ('status' in response && response.status === 'require_auth') {
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
                navigate('/login')
            } else if ('status' in response && response.status === 'error') {
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'records'))
            } else {
                setRecords(response)
            }
        }
        getRecords()
    }, [])

    return (
        <div className="page records-page container-center app-page">
            <div>
                <Header title="Inregistrari" view="records" />

                <div className="page-body">
                    {typeof(userId) !== 'undefined' ? (
                        <div className="back-arrow" onClick={() => {navigate('/users')}}>
                            <svg width="29" height="18" viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_314_403)">
                                    <path fill="var(--white)" d="M11.579 1.39585C11.5501 0.841836 11.3581 0.448077 11.0019 0.219532C10.1092 -0.359816 9.23013 0.327471 8.5867 0.88369L0.537495 7.91958C-0.180142 8.55069 -0.180142 9.4489 0.537495 10.0795L8.40375 16.9562C9.07777 17.5444 10.0339 18.409 11.0019 17.7812C11.3581 17.5515 11.5501 17.1572 11.579 16.6037V12.4018H28.6409C28.838 12.4018 28.9994 12.2443 28.9994 12.0527V5.94638C28.9994 5.75528 28.8374 5.59778 28.6409 5.59778H11.579V1.39585V1.39585Z"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_314_403">
                                        <rect width="29" height="18" fill="var(--white)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    ) : (
                        <AddRecord setRecords={setRecords}/>
                    )}
                    <Notifications />

                    {records.length > 0 ? (
                        <Records records={records} setRecords={setRecords} />
                    ) : (
                        <div>Nici o inregistrare adaugata!</div>
                    )}

                </div>
                <Nav active={'records'} />
            </div>
        </div>
    )
}