import axios from 'axios';
import { Buffer } from 'buffer';
import moment from 'moment/moment';

axios.defaults.headers.common['Accept'] = 'application/json';

const ApiService = {

    // API_URL: 'http://medipraxapi/api',
    API_URL: 'https://medipraxapi.cleardesign.ro/api',

    setAuthToken: function(token) {
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    },

    handleLogin: async function(email, password) {
        let data
        await axios.post(this.API_URL + '/login', {
            email: email,
            password: password
        })
        .then(response => {
            data = response.data
            
            if (!('authorisation' in response.data)) {
                return
            }

            //get token from response
            const token  =  response.data.authorisation.token;
            console.log('Logged in. New token: ' + token)
      
            //set token to axios common header
            ApiService.setAuthToken(token);
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    handleLogout: async function() {
        let data
        await axios.post(this.API_URL + '/logout', {})
        .then(response => {
            data = response.data

            //unset token to axios common header
            ApiService.setAuthToken(null);
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    handleRegistration: async function(firstName, lastName, email, password, passwordRepeat) {
        let data
        await axios.post(this.API_URL + '/register', {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            password_confirmation: passwordRepeat,
        })
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    handleRequestReset: async function(email) {
        let data
        await axios.post(this.API_URL + '/user/reset/request-forgot-password', {
            email: email,
        })
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    handleVerifyCode: async function(email, code) {
        let data
        await axios.post(this.API_URL + '/user/reset/check-code', {
            email: email,
            code: code,
        })
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    handleResendVerifyEmailCode: async function() {
        let data
        await axios.get(this.API_URL + '/user/email/resend')
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    handleResetPassword: async function(email, code, password, passwordRepeat) {
        let data
        await axios.post(this.API_URL + '/user/reset-password', {
            email: email,
            code: code,
            password: password,
            password_confirmation: passwordRepeat,
        })
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    handleVerifyEmail: async function(code, hash) {
        let data
        let email = Buffer.from(hash, 'base64').toString('ascii')
        await axios.post(this.API_URL + '/user/email/verify', {
            code: code,
            email: email,
        })
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    getLoggedInUser: async function() {
        let data
        await axios.get(this.API_URL + '/user/logged-in')
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    // Physicians
    getPhysicians: async function() {
        let data
        await axios.get(this.API_URL + '/user/role/physician')
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    // Users
    getUsers: async function(page = 1, sort = 'last_activity', limit = 20, search = '') {
        let data
        await axios.get(this.API_URL + '/user?page=' + page + '&sort=' + sort + '&limit=' + limit + '&s=' + encodeURIComponent(search))
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    // User
    getUser: async function(userId) {
        let data
        await axios.get(this.API_URL + '/user/' + userId)
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    // Chat
    getConversationList: async function() {
        let data
        await axios.get(this.API_URL + '/conversation')
        .then(response => {
            data = response.data.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    getConversation: async function(id) {
        let data
        await axios.get(this.API_URL + '/conversation/' + id)
        .then(response => {
            data = response.data.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    handleSendMessage: async function(message, conversation_id) {
        let data
        await axios.post(this.API_URL + '/message', {
            body: message,
            conversation_id: conversation_id,
        })
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    readConversation: async function(conversationId) {
        let data
        await axios.put(this.API_URL + '/conversation/' + conversationId + '/read')
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err.response.data)
            data = err.response.data
        });

        return data
    },

    // Records
    getRecords: async function(userId) {
        let data
        await axios.get(this.API_URL + '/records/' + userId)
        .then(response => {
            data = response.data.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    handleAddRecord: async function(pressureLow, pressureHigh, heartRate, date) {
        let data
        await axios.post(this.API_URL + '/record', {
            pressure_low: pressureLow,
            pressure_high: pressureHigh,
            heart_rate: heartRate,
            date: moment(date).format('YYYY-MM-DD HH:mm:ss'),
        })
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },
    
    // Profile
    handleUpdateProfile: async function(firstName, lastName, birthYear, sex) {
        let data
        await axios.put(this.API_URL + '/user', {
            first_name: firstName,
            last_name: lastName,
            birth_year: birthYear,
            sex: sex,
        })
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },
    
    // User
    handleUpdateUser: async function(userId, firstName, lastName, physicianId) {
        let data
        if (!physicianId) {
            physicianId = null
        }
        await axios.put(this.API_URL + '/user/' + userId, {
            first_name: firstName,
            last_name: lastName,
            physician_id: physicianId,
        })
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },

    handleAddUser: async function(firstName, lastName, password, passwordRepeat, email, physicianId) {
        let data
        await axios.post(this.API_URL + '/user', {
            first_name: firstName,
            last_name: lastName,
            password: password,
            password_confirmation: passwordRepeat,
            email: email,
            physician_id: physicianId,
        })
        .then(response => {
            data = response.data
        })
        .catch(err => {
            console.log(err)
            data = (typeof(err) === 'object' && 'response' in err) ? err.response.data : {data: {error: ['Eroare server']}, status: 'error'} 
        });

        return data
    },
    
}

export default ApiService;