import React from "react";
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';

import ApiService from "../../services/ApiService";
import NotificationsService from "../../services/NotificationsService";

import { Notifications } from "../../common/components/Notifications";
import { HeaderPublic } from "../../common/components/HeaderPublic";

import { useAtom } from "jotai";
import { notificationsAtom } from "../../common/store";

export default function ResetPasswordPage() 
{
    const [password, setPassword] = React.useState('123456')
    const [passwordRepeat, setPasswordRepeat] = React.useState('123456')
    const [email, setEmail] = React.useState('')
    const [code, setCode] = React.useState('')

    const [showLoader, setShowLoader] = React.useState(false)

    const [notifications, setNotifications] = useAtom(notificationsAtom);

    const navigate = useNavigate()
    const location = useLocation();
    const [searchParams] = useSearchParams();

    React.useEffect(() => {
        if (location.state != null && 'email' in location.state && 'code' in location.state) {
            setEmail(location.state.email)
            setCode(location.state.code)
        } else if (searchParams.get('email') != null && searchParams.get('code') != null) {
            setEmail(searchParams.get('email'))
            setCode(searchParams.get('code'))
        } else {
            // Redirect back to verify if email and code did not came from route state or url args
            setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'reset-password'))
            navigate('/verify-reset-code')
        }
    }, [])

    const handleSubmit = async () => {
        setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'reset-password'))
        setShowLoader(true)
        let response = await ApiService.handleResetPassword(email, code, password, passwordRepeat)
        if (response.status === 'success') {
            setNotifications(NotificationsService.addNotification(notifications, response.message, 'notice', 'login'))
            navigate('/login')
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'reset-password'))
            setShowLoader(false) 
        }
    }

    return (
        <div className="page login-page container-center form-page public-header-page">
            <div>
                <HeaderPublic title={'Resetare Parola'} />
                <Notifications />
                <div className="form reset-password-form">
                    <div className="field-container field-disabled">
                        <label htmlFor="email">E-mail</label>
                        <input 
                            disabled
                            id="email" 
                            type="email" 
                            value={email} 
                        />
                    </div>
                    <div className="field-container">
                        <label htmlFor="password">Parola</label>
                        <input 
                            id="password" 
                            type="password" 
                            placeholder="* * * * *" 
                            value={password} 
                            onChange={(event) => {setPassword(event.target.value)}}
                        />
                    </div>
                    <div className="field-container">
                        <label htmlFor="password-repeat">Repeta Parola</label>
                        <input 
                            id="password-repeat" 
                            type="password" 
                            placeholder="* * * * *" 
                            value={passwordRepeat} 
                            onChange={(event) => {setPasswordRepeat(event.target.value)}}
                        />
                    </div>
                    <div className="link">
                        <a onClick={(ev) => {
                                ev.preventDefault(); 
                                setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'reset-password'))
                                navigate('/login')}
                            } href="/login"
                        >Logare</a>
                    </div>


                        {showLoader ? (
                            <button className="btn disabled">
                                <div className="btn-loader"></div>
                            </button>
                        ) : (
                            <button 
                                className="btn" 
                                onClick={() => handleSubmit()}
                            >
                                Reseteaza Parola
                            </button>
                        )}
                </div>
            </div>
        </div>
    )
}