import moment from 'moment/moment';
import 'moment/locale/ro'
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../common/components/Header';

import { Nav } from '../../common/components/Nav';
import { Notifications } from '../../common/components/Notifications';
import ApiService from '../../services/ApiService';
import NotificationsService from '../../services/NotificationsService';
import ConversationService from '../../services/ConversationService';

import { useAtom } from 'jotai';
import { authAtom, messagesAtom, notificationsAtom, unreadTriggerAtom } from '../../common/store';
import NewMessage from './NewMessage';

export default function ConversationPage() 
{
    const [conversations] = useAtom(messagesAtom);
    const [unreadTrigger, setUnreadTrigger] = useAtom(unreadTriggerAtom)

    let { id } = useParams()
    let defaultConversation = {messages: []}
    Object.values(conversations).forEach((conversation, idx) => {
        if (conversation.id == id) {
            defaultConversation = conversation
        }
    })
    const [conversation, setConversation] = React.useState(defaultConversation);
    
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    }
    
    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const navigate = useNavigate()
    
    moment.locale('ro')
    
    const [auth] = useAtom(authAtom);

    console.log('Render Chat Page')

    React.useEffect(() => {
        async function getConversation() {
            let response = await ApiService.getConversation(id)
            console.log(response)
            if ('status' in response && response.status === 'require_auth') {
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
                navigate('/login')
            } else if ('status' in response && response.status === 'error') {
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'chat'))
            } else {
                response.with_users = ConversationService.getConversationParticipants(response, auth.user.id)
                setConversation(response)

                ApiService.readConversation(id).then(() => {
                    setUnreadTrigger(Math.random())
                })
            }
        }
        getConversation()
    }, [])

    React.useEffect(() => {
        scrollToBottom()
    }, [conversation, notifications]);


    return (
        <div className="page chat-page container-center app-page">
            <div>
                <Header title={conversation.with_users} view="conversations" />

                <div className="page-body">
                    <div className="back-arrow" onClick={() => {navigate('/conversations')}}>
                        <svg width="29" height="18" viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_314_403)">
                                <path fill="var(--white)" d="M11.579 1.39585C11.5501 0.841836 11.3581 0.448077 11.0019 0.219532C10.1092 -0.359816 9.23013 0.327471 8.5867 0.88369L0.537495 7.91958C-0.180142 8.55069 -0.180142 9.4489 0.537495 10.0795L8.40375 16.9562C9.07777 17.5444 10.0339 18.409 11.0019 17.7812C11.3581 17.5515 11.5501 17.1572 11.579 16.6037V12.4018H28.6409C28.838 12.4018 28.9994 12.2443 28.9994 12.0527V5.94638C28.9994 5.75528 28.8374 5.59778 28.6409 5.59778H11.579V1.39585V1.39585Z"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_314_403">
                                    <rect width="29" height="18" fill="var(--white)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="messages">
                        {
                            conversation.messages.length > 0 ?
                                conversation.messages.map(message => { 
                                    return <div className={message.sender_id === auth.user.id ? 'message own' : 'message'} key={message.id}>
                                        <div>
                                            {message.body}
                                            <div className="time">
                                                in urma cu {moment(message.created_at).fromNow(true)}
                                            </div>
                                        </div>
                                    </div>
                                })
                            :
                                <div className="no-messages">Nici un mesaj</div>
                        }
                    </div>
                    <Notifications />
                </div>
                <NewMessage conversation={conversation} setConversation={setConversation} />
                <Nav active={'conversations'} />
            </div>
        </div>
    )
}