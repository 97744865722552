import React from "react";
import {useNavigate, useSearchParams} from 'react-router-dom';

import AuthService from "../../services/ApiService";
import NotificationsService from "../../services/NotificationsService";

import { HeaderPublic } from "../../common/components/HeaderPublic";

import { useAtom } from "jotai";
import { authAtom, notificationsAtom } from "../../common/store";

export default function VerifyEmailPage() 
{
    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const [auth, setAuth] = useAtom(authAtom);

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const verifyEmail = async (code, hash) => {
        let response = await AuthService.handleVerifyEmail(code, hash)
        if (response.status === 'success') {
            setNotifications(NotificationsService.addNotification(notifications, response.message, 'notice', 'records'))
            setAuth(prevState => {
                let newData = Object.assign({}, prevState);
                newData.user = response.user;
                newData.role = response.user.role.name;
                return newData;
            })
            navigate('/records')
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
            navigate('/login')
        }
    }

    let code = searchParams.get('code')
    let hash = searchParams.get('hash')
    React.useEffect(() => {
        console.log([code, hash])
        verifyEmail(code, hash)
    }, [code, hash])

    return (
        <div className="page verify-email-page container-center form-page">
            <div>
                <HeaderPublic title={''} />
            </div>
        </div>
    )
}