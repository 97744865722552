import React from "react";
import {useNavigate} from 'react-router-dom';

import ApiService from "../../services/ApiService";
import NotificationsService from "../../services/NotificationsService";

import { Notifications } from "../../common/components/Notifications";
import { HeaderPublic } from "../../common/components/HeaderPublic";

import { useAtom } from "jotai"
import { notificationsAtom, tokenAtom } from "../../common/store"
import { crypt } from "../../common/helpers/crypt"

export default function LoginPage()
{
    const [email, setEmail] = React.useState('pacient@medipraxapi.api')
    const [password, setPassword] = React.useState('123456')
    // const [email, setEmail] = React.useState('')
    // const [password, setPassword] = React.useState('')

    const [showLoader, setShowLoader] = React.useState(false)

    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const [token, setToken] = useAtom(tokenAtom)

    const navigate = useNavigate()

    const handleSubmit = async () => {
        setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'login'))
        setShowLoader(true)
        let response = await ApiService.handleLogin(email, password)
        if (response.status === 'success') {
            await setToken(crypt(response.authorisation.token))
            if (response.user.role.name === 'Pacient') {
                navigate('/records')
            } else if (['Receptie', 'Admin'].includes(response.user.role.name)) {
                navigate('/users')
            } else {
                navigate('/conversations')
            }
        } else if (response.status === 'not_verified') {
            await setToken(crypt(response.authorisation.token))
            navigate('/verify-resend')
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'verify-resend'))
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
            setShowLoader(false) 
        }
    }

    return (
        <div className="page login-page container-center form-page public-header-page">
            <div>
                <HeaderPublic title={'Logare'} />
                <Notifications />
                <div className="form login-form">
                    <div className="field-container">
                        <label htmlFor="email">E-mail</label>
                        <input 
                            id="email" 
                            type="email" 
                            placeholder="email@email.com" 
                            value={email} 
                            onChange={(event) => {setEmail(event.target.value)}}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    handleSubmit()
                                }
                            }}
                        />
                    </div>
                    <div className="field-container">
                        <label htmlFor="password">Parola</label>
                        <input 
                            id="password" 
                            type="password" 
                            placeholder="* * * * *" 
                            value={password} 
                            onChange={(event) => {setPassword(event.target.value)}}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    handleSubmit()
                                }
                            }}
                        />
                    </div>
                    <div className="link">
                        <a onClick={(ev) => {
                                ev.preventDefault(); 
                                setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'login'))
                                navigate('/request-reset-password')}
                            } href="/request-reset-password"
                        >Resetare parola</a>
                    </div>


                        {showLoader ? (
                            <button className="btn disabled">
                                <div className="btn-loader"></div>
                            </button>
                        ) : (
                            <button 
                                className="btn" 
                                onClick={() => handleSubmit()}
                            >
                                Logare
                            </button>
                        )}

                    <div className="after-submit">
                        Utilizator nou? <a onClick={(ev) => {
                                ev.preventDefault(); 
                                setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'login'))
                                navigate('/register')}
                            } href="/register"
                        >Inregistrare</a>
                    </div>
                </div>
            </div>
        </div>
    )
}