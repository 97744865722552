import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../common/components/Header';

import { useAtom } from 'jotai';
import { authAtom, notificationsAtom } from '../../common/store';

import { Nav } from '../../common/components/Nav';
import { Notifications } from '../../common/components/Notifications';
import ApiService from '../../services/ApiService';
import NotificationsService from '../../services/NotificationsService';
import { Dropdown } from '../../common/components/Dropdown';

export default function ProfilePage() 
{
    console.log('Render Profile Page')

    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const [auth, setAuth] = useAtom(authAtom)
    const navigate = useNavigate()
    
    const [showLoader, setShowLoader] = React.useState(false)

    const [firstName, setFirstName] = React.useState(auth.user.first_name)
    const [lastName, setLastName] = React.useState(auth.user.last_name)
    const [birthYear, setBirthYear] = React.useState(auth.user.birth_year)
    const [sex, setSex] = React.useState(auth.user.sex)
    const sexes = {
        male: 'Masculin',
        female: 'Feminin',
    }

    const handleSubmit = async () => {
        setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'profile'))
        setShowLoader(true)
        let response = await ApiService.handleUpdateProfile(firstName, lastName, birthYear, sex)
        if (response.status === 'success') {
            setNotifications(NotificationsService.addNotification(notifications, response.message, 'notice', 'profile'))
            setAuth(prevState => {
                let newData = Object.assign({}, prevState);
                newData.user = response.data;
                newData.role = response.data.role.name;
                return newData;
            })
        } else if (response.status === 'require_auth') {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
            navigate('/login')
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'profile'))
        }
        setShowLoader(false) 
    }

    return (
        <div className="page profile-page container-center app-page">
            <div>
                <Header title="Profil" view="profile" />
                <div className="page-body form profile-form">
                    <Notifications />
                    <div className="field-container">
                        <label htmlFor="first-name">Nume</label>
                        <input 
                            id="first-name" 
                            type="text" 
                            value={firstName} 
                            onChange={(event) => {setFirstName(event.target.value)}}
                        />
                    </div>
                    <div className="field-container">
                        <label htmlFor="last-name">Prenume</label>
                        <input 
                            id="last-name" 
                            type="text" 
                            value={lastName} 
                            onChange={(event) => {setLastName(event.target.value)}}
                        />
                    </div>
                    <div className="row-container">
                        <div className="field-container">
                            <label htmlFor="birth-year">Anul Nasterii</label>
                            <input 
                                id="birth-year" 
                                type="text" 
                                value={birthYear} 
                                onChange={(event) => {setBirthYear(event.target.value)}}
                            />
                        </div>
                        <div className="field-container">
                            <label htmlFor="sex">Sex</label>
                            <Dropdown
                                trigger={<button><span className="selected">{sexes[sex]}</span></button>}
                                menu={Object.keys(sexes).map((key, idx) => { 
                                    return (
                                        <button onClick={() => setSex(key)}>{sexes[key]}</button>
                                    )
                                })}
                            />
                        </div>
                    </div>
                    {showLoader ? (
                        <button className="btn disabled">
                            <div className="btn-loader"></div>
                        </button>
                    ) : (
                        <button 
                            className="btn" 
                            onClick={() => handleSubmit()}
                        >
                            Salveaza
                        </button>
                    )}
                </div>
                <Nav active={'profile'} />
            </div>
        </div>
    )
}