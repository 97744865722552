import React from "react";

import ApiService from "../../services/ApiService";
import NotificationsService from "../../services/NotificationsService";

import { Notifications } from "../../common/components/Notifications";
import { HeaderPublic } from "../../common/components/HeaderPublic";

import { useAtom } from "jotai";
import { authAtom, notificationsAtom } from "../../common/store";
import { useNavigate } from "react-router-dom";

export default function VerifyResendCodePage() 
{
    const [showLoader, setShowLoader] = React.useState(false)

    const [notifications, setNotifications] = useAtom(notificationsAtom);
    const [auth] = useAtom(authAtom);

    const navigate = useNavigate()

    const handleSubmit = async () => {
        setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'verify-resend-code'))
        setShowLoader(true)
        let response = await ApiService.handleResendVerifyEmailCode()
        if (response.status === 'success') {
            setNotifications(NotificationsService.addNotification(notifications, response.message, 'notice', 'verify-resend-code'))
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'verify-resend-code'))
        }
        setShowLoader(false) 
    }

    if (typeof auth != 'object') {
        return <></>
    }

    return (
        <div className="page verify-resend-page container-center form-page public-header-page">
            <div>
                <HeaderPublic title={'Confirmare Adresa Email'} />
                <Notifications />
                <div>
                    Inainte de a va utiliza contul trebuie sa va confirmati adresa de email accesand linkul trimis in momentul inregistrarii. 
                    In cazul in care nu mai gasiti emailul de confirmare puteti trimite un nou email 
                    cu un link de confirmare pe adresa dumneavoastra de email.
                </div>
                <div className="form verify-resend-form">
                    <div className="field-container field-disabled">
                        <label htmlFor="email">E-mail</label>
                        <input 
                            disabled
                            id="email" 
                            type="email" 
                            value={auth.user.email} 
                        />
                    </div>

                    <div className="link">
                        <a onClick={(ev) => {
                                ev.preventDefault(); 
                                setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'verify-resend-code'))
                                navigate('/login')}
                            } href="/login"
                        >Logare</a>
                    </div>

                    {showLoader ? (
                        <button className="btn disabled">
                            <div className="btn-loader"></div>
                        </button>
                    ) : (
                        <button 
                            className="btn" 
                            onClick={() => handleSubmit()}
                        >
                            Retrimite Codul de Activare
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}