import React from "react";
import {useNavigate} from 'react-router-dom';

import ApiService from "../../services/ApiService";
import NotificationsService from "../../services/NotificationsService";

import { Notifications } from "../../common/components/Notifications";
import { HeaderPublic } from "../../common/components/HeaderPublic";

import { useAtom } from "jotai";
import { notificationsAtom } from "../../common/store";

export default function RegisterPage() 
{
    const [email, setEmail] = React.useState('andreiydaca@yahoo.com')
    const [password, setPassword] = React.useState('123456')
    const [passwordRepeat, setPasswordRepeat] = React.useState('123456')
    const [firstName, setFirstName] = React.useState('John')
    const [lastName, setLastName] = React.useState('Doe')
    // const [email, setEmail] = React.useState('')
    // const [password, setPassword] = React.useState('')
    // const [passwordRepeat, setPasswordRepeat] = React.useState('')
    // const [firstName, setFirstName] = React.useState('')
    // const [lastName, setLastName] = React.useState('')

    const [showLoader, setShowLoader] = React.useState(false)

    const navigate = useNavigate()

    const [notifications, setNotifications] = useAtom(notificationsAtom);

    const handleSubmit = async () => {
        setShowLoader(true)
        setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'registration'))
        let response = await ApiService.handleRegistration(firstName, lastName, email, password, passwordRepeat)
        if (response.status === 'success') {
            setNotifications(NotificationsService.addNotification(notifications, response.message, 'notice', 'login'))
            navigate('/login')
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'registration'))
            setShowLoader(false) 
        }
    }

    return (
        <div className="page register-page container-center form-page public-header-page">
            <div>
                <HeaderPublic title={'Creare cont nou'} />
                <Notifications />
                <div className="form register-form">
                    <div className="row-container">
                        <div className="field-container">
                            <label htmlFor="first-name">Nume</label>
                            <input 
                                id="first-name" 
                                type="text" 
                                placeholder="John" 
                                value={firstName} 
                                onChange={(event) => {setFirstName(event.target.value)}}
                            />
                        </div>
                        <div className="field-container">
                            <label htmlFor="last-name">Prenume</label>
                            <input 
                                id="last-name" 
                                type="text" 
                                placeholder="Doe" 
                                value={lastName} 
                                onChange={(event) => {setLastName(event.target.value)}}
                            />
                        </div>
                    </div>
                    <div className="field-container">
                        <label htmlFor="email">E-mail</label>
                        <input 
                            id="email" 
                            type="email" 
                            placeholder="email@email.com" 
                            value={email} 
                            onChange={(event) => {setEmail(event.target.value)}}
                        />
                    </div>
                    <div className="field-container">
                        <label htmlFor="password">Parola</label>
                        <input 
                            id="password" 
                            type="password" 
                            placeholder="* * * * *" 
                            value={password} 
                            onChange={(event) => {setPassword(event.target.value)}}
                        />
                    </div>
                    <div className="field-container">
                        <label htmlFor="password-repeat">Repeta Parola</label>
                        <input 
                            id="password-repeat" 
                            type="password" 
                            placeholder="* * * * *" 
                            value={passwordRepeat} 
                            onChange={(event) => {setPasswordRepeat(event.target.value)}}
                        />
                    </div>
                    <div className="link">
                        <a href="/request-reset-password">Resetare Parola</a>
                    </div>


                        {showLoader ? (
                            <button className="btn disabled">
                                <div className="btn-loader"></div>
                            </button>
                        ) : (
                            <button 
                                className="btn" 
                                onClick={() => handleSubmit()}
                            >
                                Creeaza cont
                            </button>
                        )}

                    <div className="after-submit">
                        Aveti deja un cont? <a onClick={(ev) => {
                                ev.preventDefault(); 
                                setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'registration'))
                                navigate('/login')}
                            } href="/login"
                        >Logare</a>
                    </div>
                </div>
            </div>
        </div>
    )
}