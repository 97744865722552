import moment from "moment";
import NotificationModel from "../Models/NotificationModel";

const NotificationsService = {

    removeNotificationsByGroup: function(notifications, group) {

        let removeNotificationIds = []

        Object.entries(notifications).forEach(([notificationId, notification]) => {
            if (notification.group === group) {
                removeNotificationIds.push(notification.id)
            }
        });

        Object.values(removeNotificationIds).forEach((notificationId) => {
            let notification = notifications[notificationId]
            delete notifications[notificationId]
            notification.visible = false
            notifications = { ...notifications, ...{[notificationId]: notification}}
        })

        return notifications
    },

    addNotification: function(notifications, message, type, group) {

        let notification = new NotificationModel()
        notification.type = type
        notification.group = group
        notification.message = message
        notification.created_at = moment()

        return { ...notifications, ...{[notification.id]: notification}}
    },

    setErrorsFromResponse: function(messages, notifications, group) {
        let newNotfications = []

        console.log(messages);
        if (typeof messages !== 'object') {
            messages = {
                'error': ['Eroare Server! Va rugam incercati mai tarziu!']
            }
        }
        Object.entries(messages).forEach(([field, fieldMessages]) => {
            Object.values(fieldMessages).forEach(message => {
                let exists = false
                Object.values(notifications).forEach(
                    function (notification, idx) {
                        if (notification.type === 'error' && notification.message === message && notification.group ===group) {
                            exists = true
                        }
                    }
                )

                if (!exists) {
                    let notification = new NotificationModel()
                    notification.field = field
                    notification.type = 'error'
                    notification.group = group
                    notification.message = message
                    notification.created_at = moment()
    
                    newNotfications[notification.id] = notification
                }
            });
        });

        return { ...notifications, ...newNotfications }
    },

    dismiss: function(notificationId, notifications) {
        let notification = notifications[notificationId]
        delete notifications[notificationId]
        notification.visible = false
        return { ...notifications, ...{[notificationId]: notification}}
    },

}

export default NotificationsService;