import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { RouteGuard } from './common/components/RouteGuard';
import { history } from './common/helpers/history';

import LoaderPage from './routes/loader/LoaderPage'
import LoginPage from './routes/auth/LoginPage'
import RecordsPage from './routes/records/RecordsPage';
import RegisterPage from './routes/auth/RegisterPage';
import RequestResetPage from './routes/auth/RequestResetPage';
import ResetPasswordPage from './routes/auth/ResetPasswordPage';
import VerifyResetCodePage from './routes/auth/VerifyResetCodePage';
import VerifyEmailPage from './routes/auth/VerifyEmailPage';
import VerifyResendCodePage from './routes/auth/VerifyResendCodePage';
import ProfilePage from './routes/users/ProfilePage';
import ConversationListPage from './routes/chat/ConversationListPage';
import ConversationPage from './routes/chat/ConversationPage';
import UsersPage from './routes/users/UsersPage';
import EditUserPage from './routes/users/EditUserPage';

export default function AppRouter() {
    return (
        <BrowserRouter history={history}>
            <Routes>
                <Route exact path="/" element={<RouteGuard/>}>
                    <Route exact path="/"element={
                        <LoaderPage />
                    } />
                </Route>

                <Route path="/login" element={
                    <LoginPage />
                } />

                <Route path="/register" element={
                    <RegisterPage />
                } />

                <Route path="/verify-email" element={
                    <VerifyEmailPage />
                } />

                <Route path="/request-reset-password" element={
                    <RequestResetPage />
                } />

                <Route path="/verify-reset-code" element={
                    <VerifyResetCodePage />
                } />

                <Route path="/reset-password" element={
                    <ResetPasswordPage params={{email: 'email'}} />
                } />

                <Route path="/verify-resend" element={<RouteGuard/>}>
                    <Route path="/verify-resend"element={
                        <VerifyResendCodePage />
                    } />
                </Route>

                <Route path="/records" element={<RouteGuard/>}>
                    <Route path="/records" element={
                        <RecordsPage />
                    } />
                </Route>

                <Route path="/users/:userId/records" element={<RouteGuard/>}>
                    <Route path="/users/:userId/records" element={
                        <RecordsPage />
                    } />
                </Route>

                <Route path="/conversations" element={<RouteGuard/>}>
                    <Route path="/conversations" element={
                        <ConversationListPage />
                    } />
                </Route>

                <Route path="/conversations/:id" element={<RouteGuard/>}>
                    <Route path="/conversations/:id" element={
                        <ConversationPage />
                    } />
                </Route>

                <Route path="/profile" element={<RouteGuard/>}>
                    <Route path="/profile" element={
                        <ProfilePage />
                    } />
                </Route>

                <Route path="/users" element={<RouteGuard/>}>
                    <Route path="/users" element={
                        <UsersPage />
                    } />
                </Route>

                <Route path="/user/:id" element={<RouteGuard/>}>
                    <Route path="/user/:id" element={
                        <EditUserPage />
                    } />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
