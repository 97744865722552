import { atom } from "jotai";
import { atomWithStorage } from 'jotai/utils'

export const notificationsAtom = atom([]);
export const authAtom = atom();
export const tokenAtom = atomWithStorage()

export const recordsAtom = atom([]);
export const profileAtom = atom();
export const messagesAtom = atom([]);

export const unreadAtom = atom(0);
export const unreadTriggerAtom = atom(0);
export const composeMessagesAtom = atomWithStorage('new_messages', {});

export const usersActviePageAtom = atomWithStorage('users_active', 1);
export const usersSortAtom = atomWithStorage('users_sort', 'last_activity');
export const usersSearchAtom = atomWithStorage('users_search', '');
