const ConversationService = {

    getConversationParticipants: function(conversation, userId) {
        let conversationWith = Object.values(conversation.users).filter(user => {
            return user.id !== userId
        }).map((user) => {
            return user.first_name + ' ' + user.last_name
        });

        return conversationWith.join(', ')
    },
}

export default ConversationService;