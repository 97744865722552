import moment from 'moment';
import React from 'react';

export default function Record({ date, pressureHigh, pressureLow, heartRate }) 
{
    return (
        <div className="record-row">
            <div>
                <div>{moment(date).format('D MMM YYYY')}</div>
                <div>{moment(date).format('HH:mm')}</div>
            </div>

            <div>
                <div>{pressureHigh}/{pressureLow}</div>
                <div>mmHg</div>
            </div>

            <div>
                <div>{heartRate}</div>
                <div>bpm</div>
            </div>
        </div>
    )
}