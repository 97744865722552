import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useAtom } from 'jotai'
import { authAtom, notificationsAtom } from '../../common/store'

import ApiService from '../../services/ApiService'
import NotificationsService from '../../services/NotificationsService'
import { Dropdown } from '../../common/components/Dropdown'

export default function AddUser({ setUpdateResults, role }) 
{
    const [auth] = useAtom(authAtom)

    const [isOpen, setIsOpen] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false)

    const [physicians, setPhysicians] = React.useState()

    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [passwordRepeat, setPasswordRepeat] = React.useState('')
    const [physician, setPhysician] = React.useState('')

    const [notifications, setNotifications] = useAtom(notificationsAtom);

    const navigate = useNavigate()

    React.useEffect(() => {
        if (document.querySelector('#notifications') != null) {
            document.querySelector('#notifications').scrollIntoView( { behavior: 'smooth', block: 'center' } );
        }
    }, [notifications])

    React.useEffect(() => {
        async function getPhysicians() {
            let response = await ApiService.getPhysicians()
            if ('status' in response && response.status === 'require_auth') {
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
                navigate('/login')
            } else if ('status' in response && response.status === 'error') {
                setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'users'))
            } else {
                let data = new Map([['', '- Selecteaza -']])
                console.log(response.data)
                Object.entries(response.data).forEach(([key, value]) => {
                    data.set(key, value)
                })
                console.log(data)
                setPhysicians(data)
            }
        }
        getPhysicians()
    }, [])

    const handleSubmit = async () => {
        setNotifications(NotificationsService.removeNotificationsByGroup(notifications, 'users'))
        setShowLoader(true)
        let response = await ApiService.handleAddUser(firstName, lastName, password, passwordRepeat, email, physician)
        if (response.status === 'success') {
            setIsOpen(false)
            setNotifications(NotificationsService.addNotification(notifications, response.message, 'notice', 'users'))
            setUpdateResults(response.data.id)
        } else if (response.status === 'require_auth') {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'login'))
            navigate('/login')
        } else {
            setNotifications(NotificationsService.setErrorsFromResponse(response.data, notifications, 'users'))
        }
        setShowLoader(false) 
    }

    console.log(role)

    return (
        <div className={isOpen ? 'add-user add-item open' : 'add-user add-item'}>
            <div className="add-item-btn add-user-btn" onClick={() => setIsOpen(!isOpen)}>
                <svg shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 419 511.67" xmlns="http://www.w3.org/2000/svg">
                    <path fill="var(--primary-color)" d="M 314.98 303.62 C 372.45 303.62 419 350.21 419 407.65 C 419 465.12 372.42 511.67 314.98 511.67 C 257.51 511.67 210.96 465.09 210.96 407.65 C 210.96 350.18 257.54 303.62 314.98 303.62 Z"/>
                    <path fill="var(--white)" d="M 299.42 367.05 C 299.38 362.46 298.96 359.19 304.65 359.26 L 323.1 359.49 C 329.05 359.45 330.63 361.35 330.56 366.92 L 330.56 392.08 L 355.58 392.08 C 360.17 392.05 363.44 391.62 363.36 397.32 L 363.14 415.76 C 363.17 421.72 361.28 423.3 355.71 423.24 L 330.56 423.24 L 330.56 448.38 C 330.63 453.95 329.05 455.84 323.1 455.81 L 304.65 456.03 C 298.96 456.12 299.38 452.83 299.42 448.24 L 299.42 423.24 L 274.26 423.24 C 268.67 423.3 266.79 421.72 266.82 415.76 L 266.6 397.32 C 266.51 391.62 269.8 392.05 274.39 392.08 L 299.42 392.08 L 299.42 367.05 Z"/>
                    <path fill="var(--black)" d="M 181.03 20.15 C 193.33 20.15 203.29 30.13 203.29 42.42 C 203.29 54.72 193.33 64.68 181.03 64.68 C 168.74 64.68 158.77 54.72 158.77 42.42 C 158.77 30.13 168.74 20.15 181.03 20.15 Z"/>
                    <path fill="var(--black)" d="M 93 39.4 L 139.13 39.4 C 141.84 17.18 159.77 0 181.52 0 C 203.14 0 220.97 16.95 223.86 38.94 L 270.62 39.4 C 273.23 39.4 275.32 41.49 275.32 44.11 L 275.32 95.95 C 275.32 98.55 273.23 100.65 270.62 100.65 L 93.05 100.65 C 90.49 100.65 88.34 98.55 88.34 95.95 L 88.34 44.11 C 88.3 41.512 90.402 39.387 93 39.4 Z"/>
                    <path fill="var(--black)" d="M 132.53 189.97 C 125.4 189.97 119.61 184.17 119.61 177.04 C 119.61 169.91 125.4 164.12 132.53 164.12 L 275.36 164.12 C 282.49 164.12 288.28 169.91 288.28 177.04 C 288.28 184.17 282.49 189.97 275.36 189.97 L 132.53 189.97 Z"/>
                    <path fill="var(--black)" d="M 89.5 162.6 C 97.48 162.6 103.94 169.06 103.94 177.04 C 103.94 185.02 97.48 191.48 89.5 191.48 C 81.53 191.48 75.06 185.02 75.06 177.04 C 75.06 169.06 81.53 162.6 89.5 162.6 Z"/>
                    <path fill="var(--black)" d="M 132.54 347.19 C 125.41 347.19 119.61 341.4 119.61 334.27 C 119.61 327.14 125.41 321.34 132.54 321.34 L 213.5 321.34 C 206.761 329.256 200.968 337.931 196.24 347.19 L 132.54 347.19 Z"/>
                    <path fill="var(--black)" d="M 89.5 319.84 C 97.48 319.84 103.94 326.3 103.94 334.28 C 103.94 342.25 97.48 348.71 89.5 348.71 C 81.53 348.71 75.06 342.25 75.06 334.28 C 75.06 326.3 81.53 319.84 89.5 319.84 Z"/>
                    <path fill="var(--black)" d="M 89.5 241.22 C 97.48 241.22 103.94 247.68 103.94 255.66 C 103.94 263.63 97.48 270.09 89.5 270.09 C 81.53 270.09 75.06 263.63 75.06 255.66 C 75.06 247.68 81.53 241.22 89.5 241.22 Z"/>
                    <path fill="var(--black)" d="M 132.53 268.57 C 125.4 268.57 119.61 262.78 119.61 255.65 C 119.61 248.52 125.4 242.72 132.53 242.72 L 275.36 242.72 C 282.49 242.72 288.28 248.52 288.28 255.65 C 288.28 262.78 282.49 268.57 275.36 268.57 L 132.53 268.57 Z"/>
                    <path fill="var(--black)" d="M 339.28 276.64 L 339.28 100.99 C 339.28 96.25 337.32 91.9 334.16 88.75 C 331.05 85.6 326.69 83.65 321.92 83.65 L 298.01 83.65 L 298.01 59.27 L 321.92 59.27 C 333.37 59.27 343.78 63.99 351.34 71.53 C 358.95 79.09 363.66 89.55 363.66 100.99 L 363.66 283.6 C 355.87 280.54 347.71 278.19 339.28 276.64 Z"/>
                    <path fill="var(--black)" d="M 41.73 59.27 L 65.66 59.27 L 65.66 83.65 L 41.73 83.65 C 37.19 83.65 33.03 85.41 29.93 88.26 L 29.48 88.75 C 26.34 91.88 24.38 96.23 24.38 100.99 L 24.38 416.52 C 24.38 421.27 26.34 425.62 29.48 428.76 C 32.61 431.91 36.96 433.87 41.73 433.87 L 184.35 433.87 C 186.03 442.31 188.52 450.47 191.71 458.25 L 41.73 458.25 C 30.32 458.25 19.87 453.54 12.31 445.99 C 4.72 438.44 0 427.99 0 416.52 L 0 100.99 C 0 89.51 4.7 79.07 12.25 71.52 L 13.04 70.8 C 20.54 63.67 30.66 59.27 41.73 59.27 Z"/>
                </svg>
                {
                    role === 'Medic' ?
                        <span>Adauga Medic</span>
                    :
                        <span>Adauga Pacient</span>

                }
            </div>
            <div className="form add-users-form add-items-form">
                <div className="row-container">
                    <div className="field-container">
                        <label htmlFor="first-name">Nume</label>
                        <input 
                            id="first-name" 
                            type="text" 
                            placeholder="John" 
                            value={firstName} 
                            onChange={(event) => {setFirstName(event.target.value)}}
                        />
                    </div>
                    <div className="field-container">
                        <label htmlFor="last-name">Prenume</label>
                        <input 
                            id="last-name" 
                            type="text" 
                            placeholder="Doe" 
                            value={lastName} 
                            onChange={(event) => {setLastName(event.target.value)}}
                        />
                    </div>
                </div>
                <div className="row-container">
                    <div className="field-container">
                        <label htmlFor="email">E-mail</label>
                        <input 
                            id="email" 
                            type="email" 
                            placeholder="email@email.com" 
                            value={email} 
                            onChange={(event) => {setEmail(event.target.value)}}
                        />
                    </div>
                    { role === 'Pacient' ? (
                        <div className="field-container">
                            <label htmlFor="physician">Medic</label>
                            {typeof physicians === 'undefined' ? (
                                <div className="input">
                                    <div className="btn-loader white-bg"></div>
                                </div>
                            ) : (
                                <Dropdown
                                    trigger={<button><span className="selected">{physicians.get(physician)}</span></button>}
                                    menu={Array.from(physicians.keys()).map((physicianId, idx) => { 
                                        return (
                                            <button onClick={() => setPhysician(physicianId)}>{physicians.get(physicianId)}</button>
                                        )
                                    })}
                                />
                            )}
                        </div>
                    ) : <></>}
                </div>
                <div className="row-container">
                    <div className="field-container">
                        <label htmlFor="password">Parola</label>
                        <input 
                            id="password" 
                            type="password" 
                            placeholder="* * * * *" 
                            value={password} 
                            onChange={(event) => {setPassword(event.target.value)}}
                        />
                    </div>
                    <div className="field-container">
                        <label htmlFor="password-repeat">Repeta Parola</label>
                        <input 
                            id="password-repeat" 
                            type="password" 
                            placeholder="* * * * *" 
                            value={passwordRepeat} 
                            onChange={(event) => {setPasswordRepeat(event.target.value)}}
                        />
                    </div>
                </div>
                {showLoader ? (
                    <button className="btn disabled">
                        <div className="btn-loader"></div>
                    </button>
                ) : (
                    <div className="submit-buttons">
                        <button 
                            className="btn" 
                            onClick={() => handleSubmit()}
                        >
                            Salveaza
                        </button>

                        <button 
                            className="btn btn-gray" 
                            onClick={() => setIsOpen(false)}
                        >
                            Anuleaza
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
